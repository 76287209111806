<template>
  <div class="integral-history">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <ul>
        <li v-for="item in historyList" :key="item.id">
          <div class="doc">
            <p class="p1">{{item.type_msg}}</p>
            <p class="p2">{{item.created_at}}</p>
          </div>
          <p class="p3" v-if="item.num > 0 && (item.type !== 11 && item.type !== 12)">+{{item.divide}}</p>
          <p class="p4" v-if="item.num < 0 && (item.type !== 11 && item.type !== 12)">{{item.divide}}</p>
          <p class="p5" v-if="item.num > 0 && (item.type === 11 || item.type === 12)">+{{item.divide}}</p>
          <p class="p6" v-if="item.num < 0 && (item.type === 11 || item.type === 12)">{{item.divide}}</p>
        </li>
      </ul>
    </van-list>
    <tabbar />
  </div>
</template>


<script>
import tabbar from "../../components/Tabbar.vue";

export default {
  components: {
    tabbar,
  },
  data() {
    return {
      page: 0,
      loading: false,
      finished: false,
      historyList: [],
    };
  },
  created() {},
  methods: {
    onLoad() {
      this.page++;
      this.getLog();
    },
    async getLog() {
      const res = await this.axios.get(
        `user/credit/log?limit=10&page=${this.page}`
      );
      if (res.code === 200) {
        console.log(res);
        const { list, meta } = res.data;
        list.forEach((item) => {
          if (item.type === 11 || item.type === 12) {
            item.divide = item.num + "小时";
          } else {
            item.divide = item.num;
          }
          this.historyList.push(item);
        });
        this.loading = false;
        if (this.historyList.length >= meta.total) this.finished = true;
      }
    },
  },
};
</script>


<style lang="less" scoped>
.integral-history {
  font-family: FZLTHJW--GB1-0, FZLTHJW--GB1;
  padding-top: 30px;
  ul {
    width: 92%;
    margin: auto;
    background: #fff;
    border-radius: 18px;
    li {
      width: 90%;
      height: 112px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ebebeb;
      .doc {
        .p1 {
          font-size: 28px;
          font-weight: normal;
        }
        .p2 {
          font-size: 24px;
          color: #6f6f6f;
        }
      }
      .p3 {
        font-size: 30px;
        font-family: GothamMedium;
        color: #1ec16d;
        letter-spacing: 1px;
      }
      .p4 {
        font-size: 30px;
        font-family: GothamMedium;
        color: #db2424;
        letter-spacing: 1px;
      }
      .p5 {
        font-size: 30px;
        font-family: GothamMedium;
        color: #c8a20b;
        letter-spacing: 1px;
      }
      .p6 {
        font-size: 30px;
        font-family: GothamMedium;
        color: #d7c4ae;
        letter-spacing: 1px;
      }
    }
    li:last-child {
      border: none;
    }
  }
}
</style>